import React, { useState } from 'react';
import mainTheme from '../../theme/theme';
import CountUp from 'react-countup';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

interface Props {
  number: number;
  line1: string;
  line2: string;
}

function MilestoneCard({ number, line1, line2 }: Props) {
  const [startCount, setStartCount] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) {
        setStartCount(true);
      }
    },
  });

  const leftContentStyles = {
    color: mainTheme.colors.primary,
    fontWeight: mainTheme.fontWeight.medium,
    fontFamily: mainTheme.typograpghy.headerFont,
  };

  const rightContentStyles = {
    color: mainTheme.colors.fontSecondary,
    fontWeight: mainTheme.fontWeight.medium,
    fontFamily: mainTheme.typograpghy.mainFont,
  };

  return (
    <div className="flex lg:justify-between items-center flex-col lg:flex-row justify-center lg:min-h-[20vh]" ref={ref}>
      <motion.div initial={{ opacity: 0.5 }} animate={{ opacity: inView ? 1 : 0.5 }} transition={{ duration: 3 }}>
        {startCount && <CountUp end={number} duration={2} style={leftContentStyles} className="text-5xl" suffix="+" />}
      </motion.div>
      <div className="flex flex-row lg:flex-col justify-center lg:justify-start items-start pl-0 lg:pl-4 text-xl font-medium pt-3 pb-6 lg:py-0 gap-1">
        <div style={rightContentStyles}>{line1}</div>
        <div style={rightContentStyles}>{line2}</div>
      </div>
    </div>
  );
}

export default MilestoneCard;
