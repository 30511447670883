import React from 'react';
import mainTheme from '../../../theme/theme';
import CustomButton from '../../Button/CustomButton';
import { goArrow } from '../../../assets/icons';
import { iceMan } from '../../../assets/images';
import { leafes } from '../../../assets/images';
import { motion } from 'framer-motion';

const HeroSection = () => {
  return (
    <>
      <div
        className="hero min-h-screen "
        style={{ backgroundColor: mainTheme.colors.primary, backgroundImage: `url(${leafes})` }}
      >
        <div className="hero-content flex-col lg:flex-row-reverse items-center justify-center px-10">
          <motion.img
            src={iceMan}
            alt="Ice Cream"
            initial={{ scale: 0.4 }}
            animate={{ scale: 0.8 }}
            transition={{ duration: 2 }}
            whileHover={{ scale: 1 }}
          />

          <div className="title-animation">
            <motion.h1
              className="text-6xl text-center lg:text-left flex flex-col justify-center items-center lg:items-start gap-4"
              style={{
                color: mainTheme.colors.secondary,
                marginBottom: '20px',
                fontFamily: mainTheme.typograpghy.headerFont,
              }}
              initial={{ opacity: 0, y: 80 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 2 }}
            >
              INDULGE IN <span>EVERY</span> <span>SCOOP</span>
            </motion.h1>

            <p className="py-2 md:py-5 text-3xl text-white font-medium text-center md:text-left">
              Your ulitimate ice cream destination
            </p>
            <div className="flex lg:justify-start items-center justify-center py-10 lg:py-2">
              <a href="#flavours">
                <CustomButton style={{ display: 'flex', marginRight: '20px', alignItems: 'center' }}>
                  Our Flavours <img src={goArrow} width={16} height={16} style={{ marginLeft: '5px' }}></img>
                </CustomButton>
              </a>
              <a href="#products">
                <CustomButton style={{ display: 'flex', alignItems: 'center' }}>
                  Our products <img src={goArrow} width={16} height={16} style={{ marginLeft: '5px' }}></img>
                </CustomButton>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
