import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import { FIRST_HEADER_NAVIGATION, SECOND_HEADER_NAVIGATION } from '../../../lib/constants/Navigation';
import { Link } from 'react-router-dom';
import { akinraLogo } from '../../../assets/images';
import mainTheme from '../../../theme/theme';
import { motion, AnimatePresence } from 'framer-motion';

export default function NavBar() {
  const [active, setActive] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="mx-[4%] w-[92%] max-w-[1900px]  fixed  md:top-5 top-3 z-[999] bg-white h-[50px] rounded-[8px] px-5 sm:px-8 flex flex-row items-center py-4 justify-between">
      <div className="w-full hidden lg:flex justify-between items-center  mx-10">
        <ul className="list-none hidden sm:flex flex-row gap-10 mt-2">
          {FIRST_HEADER_NAVIGATION.map((nav) => (
            <motion.li
              initial={{ opacity: 0.2 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2 }}
              key={nav.key}
              style={{ fontFamily: mainTheme.typograpghy.headerFont, color: mainTheme.colors.secondary }}
              className={`${
                active === nav.label ? mainTheme.colors.primary : mainTheme.colors.secondary
              } hover:text-taupe text-[18px]  uppercase tracking-[3px] cursor-pointer 
          }`}
              onClick={() => setActive(nav.label)}
            >
              <a href={`#${nav.key}`}>{nav.label}</a>
            </motion.li>
          ))}
        </ul>
        <Link
          to="/"
          className="flex items-center"
          onClick={() => {
            setActive('');
            window.scrollTo(0, 0);
          }}
        >
          <img src={akinraLogo} alt="logo" className="w-[150px] object-contain" />
        </Link>
        <ul className="list-none hidden sm:flex flex-row gap-10 mt-2">
          {SECOND_HEADER_NAVIGATION.map((nav) => (
            <motion.li
              initial={{ opacity: 0.2 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2 }}
              key={nav.key}
              style={{ fontFamily: mainTheme.typograpghy.headerFont, color: mainTheme.colors.secondary }}
              className={`${
                active === nav.label ? mainTheme.colors.primary : mainTheme.colors.secondary
              } hover:text-taupe text-[18px] font-medium uppercase tracking-[3px] cursor-pointer 
          }`}
              onClick={() => setActive(nav.label)}
            >
              <a href={`#${nav.key}`}>{nav.label}</a>
            </motion.li>
          ))}
        </ul>
      </div>

      {/* Mobile menu */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ x: '-100%' }}
            animate={{ x: 0 }}
            exit={{ x: '-100%' }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className="lg:hidden fixed top-0 left-0 w-full h-[70%] max-w-[250px] z-50 bg-white"
          >
            <div className="flex w-full flex-col items-start justify-start p-10 h-full">
              <div className="flex flex-row justify-between items-center w-full">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    toggleMenu();
                  }}
                >
                  <img src={akinraLogo} alt="" width={100} />
                </div>

                <button
                  className="text-black focus:outline-none"
                  onClick={toggleMenu}
                  style={{ color: mainTheme.colors.secondary }}
                >
                  <svg
                    className="w-8 h-8"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div className="text-left text-lg w-full h-full mt-10">
                <div className="flex flex-col gap-8 py-10" style={{ color: mainTheme.colors.secondary }}>
                  {FIRST_HEADER_NAVIGATION.map((item) => (
                    <ul className="list-none">
                      <a href={item.path}>
                        <li
                          key={item.key}
                          className="text-[18px] font-normal uppercase tracking-[3px] cursor-pointer "
                          style={{ fontFamily: mainTheme.typograpghy.headerFont }}
                          onClick={toggleMenu}
                        >
                          {item.label}
                        </li>
                      </a>
                    </ul>
                  ))}
                  {SECOND_HEADER_NAVIGATION.map((item) => (
                    <ul className="list-none">
                      <a href={item.path}>
                        <li
                          onClick={toggleMenu}
                          key={item.key}
                          className="text-[18px] font-bold uppercase tracking-[3px] cursor-pointer "
                          style={{ fontFamily: mainTheme.typograpghy.headerFont }}
                        >
                          {item.label}
                        </li>
                      </a>
                    </ul>
                  ))}
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Hamburger menu */}
      <div className="lg:hidden flex w-full justify-between items-center">
        <button
          className="text-black focus:outline-none"
          style={{ color: mainTheme.colors.secondary }}
          onClick={toggleMenu}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>

        <div className="flex-grow text-center items-center pt-2">
          <div className="inline-block cursor-pointer" onClick={() => window.scrollTo(0, 0)}>
            <img src={akinraLogo} alt="" width={120} />
          </div>
        </div>
      </div>
    </nav>
  );
}
