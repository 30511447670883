import React from 'react';

import './MilestoneSection.css';
import MilestoneCard from '../../cards/MilestoneCard';
import mainTheme from '../../../theme/theme';

function MilestoneSection() {
  return (
    <div
      className="flex justify-center items-center flex-wrap max-w-full py-8 px-12"
      style={{
        backgroundColor: mainTheme.colors.fontPrimary,
      }}
    >
      <div className="ml-card-1">
        <MilestoneCard number={5} line1="Years of " line2=" Experience"></MilestoneCard>
      </div>
      <div className="ml-card-2">
        <MilestoneCard number={5000} line1="Happy " line2="Customers"></MilestoneCard>
      </div>
      <div className="ml-card-3">
        <MilestoneCard number={85} line1="Our " line2="Flavours"></MilestoneCard>
      </div>
    </div>
  );
}

export default MilestoneSection;
