import React from 'react';
import { iceBackground } from '../../../assets/images';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import mainTheme from '../../../theme/theme';
import {
  blueberry,
  avocado,
  butterscotch,
  cappuccino,
  chocoStrawberry,
  chocMAshmallowa,
  strawberry,
  coconut,
  durian,
  falooda,
  fruitnut,
  kingCoc,
  peanutButter,
  kirala,
  kithul,
  kochchi,
  lemonMint,
  mango,
  mint,
  nelli,
  oriyo,
  palmyrah,
  passionFruit,
  pineapple,
  rockyRoad,
  sousop,
  trippleChoco,
  vanilla,
  veryBerry,
  runRisin,
} from '../../../assets/flavors';

export default function Flavors() {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  return (
    <div
      className="hero min-h-[120vh] flex items-center justify-center pt-24"
      style={{ backgroundColor: '#D7EFE0', backgroundImage: `url(${iceBackground})` }}
    >
      <div className="hero-content flex-col lg:flex-row flex justify-center items-center">
        <div className="flex flex-col items-center" ref={ref}>
          <motion.h1
            initial={{ x: -120 }}
            animate={{ x: inView ? 0 : -120 }}
            transition={{ duration: 1 }}
            className="text-center text-6xl  aboutus-title-animation"
            style={{
              color: mainTheme.colors.secondary,
              fontFamily: mainTheme.typograpghy.headerFont,
            }}
          >
            OUR <span style={{ color: mainTheme.colors.primary }}>Flavors</span>
          </motion.h1>
          <div className="flex flex-wrap justify-center py-24">
            <FlavorCard src={blueberry} alt="" flavorName="Blue Berry" />
            <FlavorCard src={avocado} alt="" flavorName="Avocado" />
            <FlavorCard src={butterscotch} alt="" flavorName="Butterscotch" />
            <FlavorCard src={cappuccino} alt="" flavorName="Cappucino" />
            <FlavorCard src={chocoStrawberry} alt="" flavorName="Chocolate Strawbberry" />
            <FlavorCard src={chocMAshmallowa} alt="" flavorName="Chocolate & Mashmallow" />
            <FlavorCard src={strawberry} alt="" flavorName="Strawberry" />
            <FlavorCard src={coconut} alt="" flavorName="Coconut" />
            <FlavorCard src={durian} alt="" flavorName="Durian" />
            <FlavorCard src={falooda} alt="" flavorName="Falooda" />
            <FlavorCard src={fruitnut} alt="" flavorName="Fruit & Nut" />
            <FlavorCard src={kochchi} alt="" flavorName="Kochchi" />
            <FlavorCard src={kithul} alt="" flavorName="Kithul" />
            <FlavorCard src={kingCoc} alt="" flavorName="King coconut" />
            <FlavorCard src={kirala} alt="" flavorName="Kirala" />
            <FlavorCard src={lemonMint} alt="" flavorName="Lemon mint" />
            <FlavorCard src={mango} alt="" flavorName="Mango" />
            <FlavorCard src={mint} alt="" flavorName="Mint" />
            <FlavorCard src={nelli} alt="" flavorName="Nelli" />
            <FlavorCard src={oriyo} alt="" flavorName="Oriyo" />
            <FlavorCard src={peanutButter} alt="" flavorName="Peanu Butter" />
            <FlavorCard src={palmyrah} alt="" flavorName="Palmyrah" />
            <FlavorCard src={passionFruit} alt="" flavorName="Passion Fruit" />
            <FlavorCard src={pineapple} alt="" flavorName="Pineapple" />
            <FlavorCard src={rockyRoad} alt="" flavorName="Rocky Road" />
            <FlavorCard src={sousop} alt="" flavorName="Soursop" />
            <FlavorCard src={trippleChoco} alt="" flavorName="Tripple Choco" />
            <FlavorCard src={vanilla} alt="" flavorName="Vanilla" />
            <FlavorCard src={veryBerry} alt="" flavorName="Very Berry" />
            <FlavorCard src={runRisin} alt="" flavorName="Rum & Raisin" />
          </div>
        </div>
      </div>
    </div>
  );
}

interface FlavorCardProps {
  src: string;
  alt: string;
  flavorName: string;
}

const FlavorCard: React.FC<FlavorCardProps> = ({ src, alt, flavorName }) => {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.5, // Change this to false if you want the animation to trigger again whenever it comes in view
  });

  return (
    <div className="flex flex-col px-5  items-center  sm:m-0 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5" ref={ref}>
      <motion.img
        initial={{ opacity: 0.2, scale: 0.5 }}
        animate={{ opacity: inView ? 1 : 0.2, scale: inView ? 1 : 0.5 }}
        whileHover={{ scale: 1.4, transition: { duration: 0.25 } }}
        src={src}
        alt={alt}
        className="lg:h-60 h-50  "
      />
      <motion.div
        initial={{ y: -30 }}
        animate={{ y: inView ? 0 : -30, transition: { duration: 0.5 } }}
        className="text-center text-[21px] font-medium"
        style={{
          color: mainTheme.colors.fontSecondary,
          fontFamily: mainTheme.typograpghy.headerFont,
          fontWeight: '400',
        }}
      >
        {flavorName}
      </motion.div>
    </div>
  );
};
