import React from 'react';
import mainTheme from '../../../theme/theme';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { TextField } from '@mui/material';
import { FaFacebookSquare } from 'react-icons/fa';
import { BsInstagram } from 'react-icons/bs';
import { AiFillTikTok } from 'react-icons/ai';

const Footer = () => {
  const { ref, inView } = useInView({
    triggerOnce: false,
  });

  return (
    <div className=" bg-[#fffabe] flex flex-col justify-center items-center py-24 max-w-screen overflow-hidden">
      <div className="w-full flex justify-center items-center">
        <div ref={ref}>
          <motion.h1
            initial={{ x: -120 }}
            animate={{ x: inView ? 0 : -120 }}
            transition={{ duration: 1 }}
            className="text-center text-6xl uppercase mb-8"
            style={{
              color: mainTheme.colors.secondary,
              fontFamily: mainTheme.typograpghy.headerFont,
            }}
          >
            contact us
          </motion.h1>
        </div>
      </div>
      <motion.div
        className="text-3xl font-semibold pt-10 "
        style={{ color: mainTheme.colors.fontSecondary }}
        initial={{ x: 80 }}
        animate={{ x: inView ? 0 : 80 }}
        transition={{ duration: 1 }}
      >
        Find us on Google Maps
      </motion.div>
      <div className="text-cl font-light pb-8 pt-2" style={{ color: mainTheme.colors.fontSecondary }}>
        ( Open hours - 9 am to 10pm )
      </div>
      <div className="w-full flex justify-center items-center rounded-lg ">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.9996004722866!2d79.88067947475557!3d6.769900793226922!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae245b16ee9e57b%3A0xa363f679d23f08bf!2sAkinra%20Ice%20Cream!5e0!3m2!1sen!2slk!4v1719167460837!5m2!1sen!2slk"
          height="200"
          style={{ borderRadius: '12px' }}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="border-none w-[90%] md:w-[80%]"
        ></iframe>
      </div>
      <motion.div
        className="text-3xl font-semibold  pb-8 pt-24"
        style={{ color: mainTheme.colors.fontSecondary }}
        initial={{ x: 0 }}
        animate={{ x: inView ? 80 : 0 }}
        transition={{ duration: 1 }}
      >
        Send us a message
      </motion.div>
      <div className="flex flex-col md:flex-row justify-between items-center w-[80%] lg:w-[60%] md:gap-10 gap-2 md:py-3 py-1">
        <CustomTextField title="Name" placeholder="Enter your name" />
        <CustomTextField title="Email" placeholder="Enter your email" />
      </div>
      <div className="flex flex-col md:flex-row justify-between items-center w-[80%] lg:w-[60%] md:gap-10 gap-2 md:py-3 py-1">
        <CustomTextField title="Subject" placeholder="Enter your subject" />
        <CustomTextField title="Phone" placeholder="Enter your number" />
      </div>
      <div className="flex flex-col md:flex-row justify-between items-center w-[80%] lg:w-[60%] md:gap-10 gap-2 md:py-3 py-1">
        <CustomTextField title="Subject" placeholder="Write your message here" multiline={true} rows={5} />
      </div>
      <button className="bg-[#6B024D] w-[80%] md:w-[30%] py-2 rounded-lg text-xl text-white font-medium mt-10">
        Submit
      </button>

      {/* Connect with us */}
      <div>
        <motion.div
          className="text-3xl font-semibold  pb-8 pt-24 text-center"
          style={{ color: mainTheme.colors.fontSecondary }}
          initial={{ x: 0 }}
          animate={{ x: inView ? 160 : 0 }}
          transition={{ duration: 1 }}
        >
          Connect with us
        </motion.div>
        <div className="flex lg:flex-row flex-col justify-between items-center lg:items-start">
          <div className="flex-col justify-start items-center px-0 md:px-10 py-4 md:py-2 text-center md:text-left">
            <FooterTitle text="Mobile" />
            <FooterData text="0114 199 604" />

            <FooterData text="0114 483 486" />
            <FooterData text="0773 710 931" />
            <FooterData text="0761 338 604" />
            <FooterData text="0770 834 307" />
          </div>
          <div className="flex-col justify-start items-center px-0 md:px-10 py-4 md:py-2 text-center md:text-left">
            <FooterTitle text="Email" />
            <FooterData text="akinraicecream94@gmail.com" />
          </div>
          <div className="flex-col justify-start items-center px-0 md:px-10 py-4 md:py-2 text-center md:text-left">
            <FooterTitle text="Address" />
            <FooterData text="Akinra Pvt Ltd, No : 417, New Galle Road, Moratuwa" />
          </div>
        </div>
        <div className="flex flex-row justify-center items-center">
          <SocialButton
            bgColor="#1877F2"
            children={<FaFacebookSquare size={30} />}
            onClick={() => window.open('https://web.facebook.com/AkinraIceCream', '_blank')}
          />
          <SocialButton
            bgColor="#dd2a7b"
            children={<BsInstagram size={30} />}
            onClick={() => window.open('https://www.instagram.com/akinra_ice_cream/', '_blank')}
          />
          <SocialButton children={<AiFillTikTok size={40} />} bgColor="black" />
        </div>
      </div>
    </div>
  );
};

export default Footer;

const CustomTextField = ({
  title,
  placeholder,
  multiline = false,
  rows = 4,
}: {
  title: string;
  placeholder: string;
  multiline?: boolean;
  rows?: number;
}) => {
  return (
    <div className="flex flex-col items-start space-y-1 py-2 md:py-0 w-full">
      <div className="text-xl font-medium" style={{ color: mainTheme.colors.fontSecondary }}>
        {title}
      </div>
      <TextField size="small" placeholder={placeholder} className="w-full" multiline={multiline} rows={rows} />
    </div>
  );
};

const FooterTitle = ({ text }: { text: string }) => {
  return <div className="text-lg font-medium text-gray-500">{text}</div>;
};

const FooterData = ({ text }: { text: string }) => {
  return <div className="font-normal text-gray-700 text-sm">{text}</div>;
};

const SocialButton = ({
  children,
  bgColor,
  onClick,
}: {
  children: React.ReactNode;
  bgColor: string;
  onClick?: () => void;
}) => {
  return (
    <button
      className="w-[50px] h-[50px] py-2 rounded-md text-xl text-white my-2 mx-2 flex justify-center items-center"
      style={{ backgroundColor: bgColor }}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
