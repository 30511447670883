import React from 'react';
import mainTheme from '../../../theme/theme';

const Reserved = () => {
  const handleClick = () => {
    // Assuming you want to navigate to an external URL
    window.open('http://www.chamarawimalaweera.com', '_blank');
  };
  return (
    <div
      className="w-full text-white text-sm font-normal px-4 md:px-24  py-4 text-center md:text-left"
      style={{ backgroundColor: mainTheme.colors.secondary }}
    >
      @ All Rights Reserved By{' '}
      <span
        onClick={handleClick}
        style={{ cursor: 'pointer', fontFamily: mainTheme.typograpghy.headerFont, fontWeight: '400' }}
      >
        Chamara
      </span>
    </div>
  );
};

export default Reserved;
