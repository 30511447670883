import React, { CSSProperties } from 'react';
import mainTheme from '../../theme/theme';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

interface CustomButtonProps {
  children: React.ReactNode;
  style?: CSSProperties;
  onClick?: () => void;
  borderRadius?: string;
}

function CustomButton({ children, style, onClick, borderRadius = '20px' }: CustomButtonProps) {
  const defaultStyles = {
    backgroundColor: mainTheme.colors.fontPrimary,
    color: mainTheme.colors.secondary,
    borderRadius: borderRadius,
    padding: '8px 16px',
    fontSize: '16px',
    fontFamily: mainTheme.typograpghy.mainFont,
    fontWeight: mainTheme.fontWeight.medium,
    border: 'none',
    // width: '450px',
    //height: '60px',
  };

  const buttonStyles = {
    ...defaultStyles,
    ...style,
  };
  return (
    <div>
      <motion.button
        className="primary-motion."
        style={buttonStyles}
        onClick={onClick}
        whileHover={{ scale: 1.2 }}
        whileTap={{ scale: 0.7, rotate: '5deg' }}
        transition={{
          duration: 0.25,
          ease: 'easeInOut',
        }}
      >
        {children}
      </motion.button>
    </div>
  );
}

CustomButton.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  onclick: PropTypes.func,
};

export default CustomButton;
