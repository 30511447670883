import React from 'react';
import './Loader.css';
import { akinraLogo } from '../../assets/images';

export default function Loader() {
  return (
    <div className="loader-container min-h-screen flex flex-col justify-center items-center">
      <img src={akinraLogo} alt="" width={200} className="mb-10" />
      <div className="loader">
        <div className="loader-bar"></div>
      </div>
    </div>
  );
}
