import React from 'react';
import { iceBackground } from '../../../assets/images';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import mainTheme from '../../../theme/theme';

export default function Products() {
  const { ref, inView } = useInView({
    triggerOnce: false,
  });

  return (
    <div
      className="hero min-h-[120vh]"
      style={{ backgroundColor: '#E0BAE0', backgroundImage: `url(${iceBackground})` }}
    >
      <div className="hero-content flex-col lg:flex-row">
        <div className="lg:ml-6" ref={ref}>
          <motion.h1
            initial={{ x: -120 }}
            animate={{ x: inView ? 0 : -120 }}
            transition={{ duration: 1 }}
            className="text-center lg:text-left text-6xl"
            style={{
              color: mainTheme.colors.secondary,
              fontFamily: mainTheme.typograpghy.headerFont,
            }}
          >
            OUR <span style={{ color: mainTheme.colors.primary }}>Products</span>
          </motion.h1>
        </div>
      </div>
    </div>
  );
}
