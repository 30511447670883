export const FIRST_HEADER_NAVIGATION = [
  {
    key: 'about',
    label: 'About',
    path: '#about',
  },
  {
    key: 'flavours',
    label: 'Flavours',
    path: '#flavours',
  },
];

export const SECOND_HEADER_NAVIGATION = [
  {
    key: 'products',
    label: 'Products',
    path: '#products',
  },
  {
    key: 'contact',
    label: 'Contact',
    path: '#contact',
  },
];
