import React from 'react';
import mainTheme from '../../../theme/theme';
import { right } from '../../../assets/icons';
import { about, iceBackground } from '../../../assets/images';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

export const About = () => {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  return (
    <div
      className="hero min-h-[120vh]"
      // style={{ background: 'linear-gradient(-7deg, #FFE6C6 85%, #FA68E5 15%)', backgroundImage: `url(${leafes})` }}
      style={{ backgroundColor: '#FFE6C6', backgroundImage: `url(${iceBackground})` }}
    >
      <div className="hero-content flex-col lg:flex-row">
        <motion.img
          initial={{ opacity: 1, scale: 0.8 }}
          whileHover={{ scale: 1.1 }}
          animate={{ opacity: inView ? 1 : 1, scale: inView ? 1 : 0.8 }}
          transition={{ duration: 1 }}
          src={about}
          className="lg:ml-5  w-[450px] py-10 border-none"
          style={{ borderRadius: '24px' }}
        />
        <div className="lg:ml-6 py-10" ref={ref}>
          <motion.h1
            initial={{ opacity: 0.8, y: 80 }}
            animate={{ opacity: inView ? 1 : 0.8, y: inView ? 0 : 160 }}
            transition={{ duration: 1 }}
            className="text-center lg:text-left text-6xl aboutus-title-animation"
            style={{
              color: mainTheme.colors.secondary,
              fontFamily: mainTheme.typograpghy.headerFont,
            }}
          >
            AKINRA <span style={{ color: mainTheme.colors.primary }}>ICE CREAM</span>
          </motion.h1>
          <motion.p
            initial={{ opacity: 0.8, y: 80 }}
            animate={{ opacity: inView ? 1 : 0.8, y: inView ? 0 : 120 }}
            transition={{ duration: 1 }}
            className="py-6 text-center lg:text-left text-lg"
            style={{ color: mainTheme.colors.fontSecondary }}
          >
            We create authentic 100% natural ice cream, juices, and cakes. None of our products contain any coloring,
            preservatives, flavoring, or any other additive ingredients. All our recipes are carefully handcrafted to
            give you a healthy, natural, and delicious treat.
          </motion.p>
          <IconText text="Fresh Fruits & Fresh Milk Only" />
          <IconText text="100% Natural" />
          <IconText text="No Artificial Flavors" />
          <IconText text="No Artificial Preservatives" />
          <IconText text="No Artificial Colors" />
        </div>
      </div>
    </div>
  );
};

const IconText = ({ text }: { text: string }) => {
  return (
    <motion.p
      initial={{ y: 80 }}
      animate={{ y: 0 }}
      transition={{ duration: 2 }}
      className="py-1 pl-10 lg:px-0 text-left text-lg"
      style={{ display: 'flex', alignItems: 'center', color: '#828282' }}
    >
      <img src={right} width={20} height={20} style={{ marginRight: '10px' }} alt="Icon" /> {text}
    </motion.p>
  );
};
